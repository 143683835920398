.modal {
  background-color: #fff;
  border-radius: 3px;
  color: #1a1a1a;
  max-width: 90%;
  margin: 3vh auto;
  position: relative;

  &:focus {
    outline-width: 0;
  }

  @media (min-width: 768px) {
    margin: 10vh auto;
    width: 480px;
  }
}

.modal--full-width {
  width: 100%;

  @media (min-width: 1024px) {
    width: 70%;
  }
}

.modal--medium {
  width: 600px;
}

.modal--narrow {
  width: 360px;
}

.modal--small {
  width: 360px;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: color(#000 a(60%));
  z-index: var(--layers-modal);

  overflow-y: scroll;
}

.modal-header {
  padding: 20px 0;
  box-shadow: inset 0 -2px 0 0 #eee;
}

.modal-header-title {
  height: 24px;
  margin-left: 14px;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 1.2;
  text-align: left;
}

.modal-footer {
  padding: 24px 14px;
  box-shadow: inset 0 1px 0 0 #eee;
}

.modal-title--small {
  margin: 22px 0 6px;
}

.modal-text--small {
  max-width: 280px;
  margin: 0 auto 40px auto;
  color: #666;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
}

.modal-buttons--small {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
}

.modal-button--small {
  width: 50%;
  margin: 0 10px;
  padding: 12px 0;
}

.modal-textfield--full-width {
  width: 100%;
}

.modal-image:hover {
  cursor: grab;
}

.modal-image:active {
  cursor: grabbing;
}

.modal-content {
  padding: 20px;
}

.modal__textfield {
  margin-top: 10px;
  min-height: 100px;
}

.modal__actions {
  margin-top: 20px;

  span {
    margin-left: 10px;
  }

  .nu-button + .link {
    margin-left: 10px;
  }
}

.former-modal__container {
  margin: 0 auto;
  max-width: 360px;
  padding-top: 24px;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

/*applied via JS when opening a modal with fixedBody = true*/
/*prevents cursor rendering errors in iOS11*/
.body--fixed {
  position: fixed;
}

.alerts-modal__buttons {
  display: flex;
  justify-content: flex-end;
}
